import * as React from "react";
import * as T from "types";
import { Node } from "./Node";
import { SVGPanZoom } from "components/SVGPanZoom";

interface Props {
  root: T.TTreeNode;
}

export const Tree: React.SFC<Props> = (p) => {
  const [elementToZoomTo, setElementToZoomTo] = React.useState<
    React.MutableRefObject<any>
  >();

  if (!p.root) {
    return null;
  }

  return (
    <SVGPanZoom elementToZoomTo={elementToZoomTo}>
      <Node
        node={p.root}
        path={T.makePath(p.root.title)}
        coord={{ x: 0, y: 0 }}
        zoomToNode={setElementToZoomTo}
      />
    </SVGPanZoom>
  );
};
