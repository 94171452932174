import * as T from "./types";
import * as Im from "immutable";
import { Value } from "slate";
import { getCookieValue } from "utils/misc";

const defaultExtra = {
  headers: {
    "Content-Type": "application/json",
  },
};

const json2doc = (orig: any, with_body: boolean = false) => {
  let doc = T.Document(orig);
  doc = doc.set("date_created", new Date(orig.date_created));
  doc = doc.set("date_modified", new Date(orig.date_created));
  if (with_body) {
    if (Object.keys(orig["body"]).length) {
      doc = doc.set("body", Value.fromJSON(orig["body"]));
    } else {
      doc = doc.set("body", T.initValue([]));
    }
  } else {
    doc = doc.set("body", null);
  }

  return doc;
};

const rawTree2Tree = (rawTree: object): T.TTreeNode => {
  if (!("title" in rawTree)) {
    return null;
  }

  return T.TreeNode({
    title: rawTree["title"],
    children: Im.List(rawTree["children"].map(rawTree2Tree)),
  });
};

export async function fetchTree(username?: string) {
  username = username || "";
  const extra = { ...defaultExtra };
  const resp = await fetch(`/api/tree/${username}`, extra);
  const json = await resp.json();
  return rawTree2Tree(json);
}

export async function fetchDoc(path: T.Path) {
  const extra = { ...defaultExtra };
  const resp = await fetch(`/api/document/one/${path.join("/")}`, extra);
  const orig = await resp.json();
  return json2doc(orig, true);
}

export async function patchDoc(path: T.Path, doc: T.TDocument) {
  const body = doc.body.toJS();
  // body["body"] = doc.body.toJSON();

  const extra = {
    ...defaultExtra,
    body: JSON.stringify(body),
    method: "PATCH",
  };
  const resp = await fetch(`/api/document/one/${path.join("/")}`, extra);
  if (!resp.ok) {
    throw new Error("Couldn't update document");
  }
  return null;
}

export async function createDoc(path: T.Path) {
  const extra = {
    ...defaultExtra,
    method: "POST",
  };

  const resp = await fetch(`/api/document/one/${path.join("/")}`, extra);
  if (!resp.ok) {
    throw new Error("Couldn't create new document");
  }
}

export async function deleteDoc(path: T.Path) {
  const extra = {
    ...defaultExtra,
    method: "DELETE",
  };

  const resp = await fetch(`/api/document/one/${path.join("/")}`, extra);
  if (!resp.ok) {
    throw new Error("Couldn't delete document");
  }
}

export async function login(username: string, password: string) {
  const extra = {
    ...defaultExtra,
    body: JSON.stringify({
      username,
      password,
    }),
    method: "POST",
  };

  const resp = await fetch(`/api/auth/login`, extra);
  if (!resp.ok) {
    throw new Error("Couldn't log in");
  }
}

export async function fetchuser() {
  const extra = { ...defaultExtra };
  const resp = await fetch(`/api/user/`, extra);
  const orig = await resp.json();
  const user = T.User(orig);

  return user;
}

export async function uploadImage(inputFiles: any[]) {
  const formData = new FormData();

  const files = Array.from(inputFiles);
  files.forEach((file: Blob, i) => {
    formData.append("file", file);
  });

  const auth_jwt = getCookieValue("AUTH");
  const api_url = `https://europe-west3-takuino.cloudfunctions.net/takuino-uploads?jwt_token=${auth_jwt}`;

  return fetch(api_url, {
    method: "POST",
    body: formData,
  }).then((res) => res.json());
}

export async function moveDoc(source: T.Path, destination: T.Path) {
  const body = {
    source: source.join("/"),
    destination: destination.join("/"),
  };

  const extra = {
    ...defaultExtra,
    body: JSON.stringify(body),
    method: "POST",
  };
  const resp = await fetch(`/api/document/move`, extra);
  if (!resp.ok) {
    throw new Error("Couldn't update document");
  }
  return null;
}
