import * as React from "react";
import * as T from "types";
import * as Api from "api";
import { Map } from "immutable";

export interface DocStoreState {
  docs: T.TDocumentMap;
  tree: T.TTreeNode;
}

const initState = {
  docs: Map() as T.TDocumentMap,
  tree: null,
};

type Action =
  | { type: "tree_load"; tree: T.TTreeNode }
  | { type: "doc_add"; path: T.Path }
  | { type: "doc_del"; path: T.Path }
  | { type: "purge" }
  | { type: "doc_update"; path: T.Path; doc: T.TDocument };

const reducer = (state: DocStoreState, action: Action): DocStoreState => {
  switch (action.type) {
    case "tree_load": {
      return {
        ...state,
        tree: action.tree,
      };
    }
    case "doc_add": {
      return {
        ...state,
        docs: state.docs.set(
          action.path,
          T.Document().set("title", action.path.last())
        ),
      };
    }
    case "doc_del": {
      return {
        ...state,
        docs: state.docs.remove(action.path),
      };
    }
    case "doc_update": {
      return {
        ...state,
        docs: state.docs.set(action.path, action.doc),
      };
    }
    case "purge": {
      return {
        ...state,
        docs: initState.docs,
        tree: initState.tree,
      };
    }
    default:
      throw new Error(`unknown action: ${action}`);
  }
};

const actionCreator = (dispatch: React.Dispatch<Action>) => ({
  dispatch,
  loadOwnTree: () =>
    (async function () {
      const tree = await Api.fetchTree();
      dispatch({ type: "tree_load", tree });
    })(),
  loadDoc: (path: T.Path) =>
    (async function () {
      const doc = await Api.fetchDoc(path);
      dispatch({ type: "doc_update", path, doc });
    })(),
  addDoc: (path: T.Path) =>
    (async function () {
      await Api.createDoc(path);
      actionCreator(dispatch).loadOwnTree();
      // dispatch({ type: "doc_add", path });
      // TODO: who decides what first body looks like?
    })(),
  delDoc: (path: T.Path) =>
    (async function () {
      await Api.deleteDoc(path);
      await actionCreator(dispatch).loadOwnTree();
      dispatch({ type: "doc_del", path });
    })(),
  updateDoc: (path: T.Path, doc: T.TDocument) =>
    (async function () {
      dispatch({ type: "doc_update", path, doc });
      // Api.patchDoc(path, doc);
      // TODO: call api?
    })(),
  moveDoc: (source: T.Path, destination: T.Path) =>
    (async function () {
      await Api.moveDoc(source, destination);
      dispatch({ type: "purge" });
      actionCreator(dispatch).loadOwnTree();
    })(),
});

export const DocStoreDispatchCtx = React.createContext<
  ReturnType<typeof actionCreator>
>(null);

export const useDocStore = () => {
  const [state, dispatch] = React.useReducer(reducer, initState);
  const actions = React.useMemo(() => actionCreator(dispatch), []);

  const Context = React.useCallback(
    (p: any) => (
      <DocStoreDispatchCtx.Provider value={actions}>
        {p.children}
      </DocStoreDispatchCtx.Provider>
    ),
    []
  );

  return { state, Context, actions };
};
