import * as React from "react";
import * as Ui from "semantic-ui-react";
import * as T from "types";
import { List } from "immutable";
import stringSimilarity from "string-similarity";

interface Props {
  tree: T.TTreeNode;
  onSelect: (n: T.Path) => void;
  defaultPath?: T.Path;
  searchQuery?: string;
}

const searchFunction = (options: Ui.DropdownItemProps[], query: string) => {
  const topCandidatePaths = options
    .filter(
      (i) => stringSimilarity.compareTwoStrings(i.text.toString(), query) > 0.2
    )
    .map((i) => i.value.toString());

  return options.filter((i) =>
    topCandidatePaths.some((c) => i.value.toString().startsWith(c))
  );

  // simpler filter
  //   return options.filter(
  //     (i) => stringSimilarity.compareTwoStrings(i.text.toString(), query) > 0.2
  //   );
};

export const NodeDropdown: React.SFC<Props> = (p) => {
  const renderItem = (path: T.Path) => (
    <div>
      <Ui.Breadcrumb>
        {path
          .slice(0, -1)
          .map((a) => (
            <Ui.Breadcrumb.Section key={a}>{a}</Ui.Breadcrumb.Section>
          ))
          .interpose(<Ui.Breadcrumb.Divider />)}
        <Ui.Breadcrumb.Divider />
        <Ui.Breadcrumb.Section active>{path.last()}</Ui.Breadcrumb.Section>
      </Ui.Breadcrumb>
    </div>
  );

  const flat = React.useMemo(
    () =>
      T.tree2PathList(p.tree)
        .map((p) => ({
          key: p,
          value: p,
          content: renderItem(p),
          text: p.last(),
        }))
        .toJS(),
    [p.tree]
  );

  const onChange = (event: React.SyntheticEvent, data: Ui.DropdownProps) => {
    p.onSelect(List(data.value as string[]));
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <div style={{}}>
      <Ui.Dropdown
        inline
        searchQuery={p.searchQuery}
        open={p.searchQuery !== undefined || undefined}
        // content={p.defaultPath ? renderItem(p.defaultPath) : "Search"}
        onChange={onChange}
        selectOnNavigation={false}
        text="Search"
        options={flat}
        scrolling
        search={searchFunction}
      />
    </div>
  );
};
