import * as React from "react";
import * as Ui from "semantic-ui-react";
import * as T from "types";
import { DocStoreDispatchCtx } from "containers/docStore";

export const NewPage = (p: { parentPath: T.Path }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  let [newTitle, setNewTitle] = React.useState("");
  const actions = React.useContext(DocStoreDispatchCtx);

  enum Status {
    Composing,
    WaitingResponse,
  }
  const [status, setStatus] = React.useState<Status>(Status.Composing);
  const [errorMsg, setErrorMsgInternal] = React.useState("");
  const setErrorMsg = (s: string) => {
    setStatus(Status.Composing);
    setErrorMsgInternal(s);
  };

  const submit = (optionalTitle?: string) => {
    newTitle = optionalTitle || newTitle;
    setStatus(Status.WaitingResponse);
    setErrorMsgInternal("");

    if (newTitle.length === 0) {
      setErrorMsg("Title cannot be empty");
      return;
    }

    const safeTitle = T.title2pathSafeTitle(newTitle);

    const newPath = p.parentPath.push(safeTitle);

    actions.addDoc(newPath).then(() => {
      setStatus(Status.Composing);
      setNewTitle("");
      setIsOpen(false);
    });
  };

  const submitDate = () => {
    submit(new Date().toString().split(" GMT")[0]);
  };

  return (
    <Ui.Popup
      open={isOpen}
      trigger={<Ui.Icon name="add" inverted />}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      closeOnTriggerClick={true}
      openOnTriggerClick={true}
      openOnTriggerMouseEnter={false}
      closeOnTriggerBlur={false}
      openOnTriggerFocus={false}
      closeOnTriggerMouseLeave={false}
      closeOnEscape={false}
      closeOnPortalMouseLeave={false}
    >
      <p>New page</p>
      {errorMsg.length !== 0 && <Ui.Message negative>{errorMsg}</Ui.Message>}
      <Ui.Form>
        <Ui.Input
          disabled={status === Status.WaitingResponse}
          error={errorMsg.length !== 0}
          value={newTitle}
          onChange={(a, b) => setNewTitle(b.value)}
        />
        <Ui.Button.Group>
          <Ui.Button
            disabled={status === Status.WaitingResponse}
            onClick={() => submit()}
          >
            Create
          </Ui.Button>
          <Ui.Button
            disabled={status === Status.WaitingResponse}
            onClick={submitDate}
          >
            Create Date
          </Ui.Button>
        </Ui.Button.Group>
      </Ui.Form>
    </Ui.Popup>
  );
};
