import * as React from "react";
import * as Ui from "semantic-ui-react";
import * as T from "types";
import * as Api from "api";
import { DocStoreDispatchCtx } from "containers/docStore";
import { PositiveNegativeModal } from "containers/Components";
import { css } from "emotion";
import { BlockType } from "./Editor/schema";

interface Props {
  doc: T.TDocument;
  path: T.Path;
  editorRef: React.MutableRefObject<undefined>;
}

export const Menu: React.SFC<Props> = (p) => {
  const actions = React.useContext(DocStoreDispatchCtx);
  const [moveDestRawPath, setMoveDestRawPath] = React.useState(
    p.path.join("/")
  );

  const onDelete = () => actions.delDoc(p.path);

  const onUploadImage = (e) => {
    Api.uploadImage(e.target.files).then((d) => {
      const imgUrl = d["url"];
      (p.editorRef.current as any).editor.command((editor) => {
        editor.insertBlock({
          type: BlockType.Image,
          data: {
            url: imgUrl,
          },
        });
      });
    });
  };

  const onMove = () => {
    if (!moveDestRawPath) {
      return;
    }
    actions.moveDoc(p.path, T.makePath(moveDestRawPath));
  };

  return (
    <div>
      <Ui.Menu icon>
        <Ui.Menu.Item name="gamepad">
          <label>
            <Ui.Icon name="image outline" />
            <input
              className={css`
                display: none;
              `}
              type="file"
              onChange={onUploadImage}
            />
          </label>
        </Ui.Menu.Item>
        <Ui.Menu.Item>
          <PositiveNegativeModal
            trigger={(p) => <Ui.Icon {...p} name="trash" text="Delete" />}
            onPositive={onDelete}
            description={
              <span>
                <Ui.Header icon="trash" content="Delete Note" />,
                <Ui.Modal.Content>
                  <p>Do you want to delete this note?</p>
                </Ui.Modal.Content>
              </span>
            }
          />
        </Ui.Menu.Item>

        <Ui.Menu.Item>
          <Ui.Popup trigger={<Ui.Icon name="exchange" />}>
            <p>Move this node</p>
            <Ui.Form>
              <Ui.Input
                placeholder="new path"
                value={moveDestRawPath}
                onChange={(a, b) => setMoveDestRawPath(b.value)}
              />
              <Ui.Button onClick={onMove}>Move</Ui.Button>
            </Ui.Form>
          </Ui.Popup>
        </Ui.Menu.Item>
      </Ui.Menu>
      <div
        className={css`
          font-size: 0.75em;
          color: gray;
          width: 90%;
        `}
      >
        Created: {p.doc && p.doc.date_created.toLocaleString()}
        {"     "}
        Last Edited: {p.doc && p.doc.date_modified.toLocaleString()}
      </div>
    </div>
  );
};
