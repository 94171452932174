import * as React from "react";
import * as Ui from "semantic-ui-react";
import { login } from "api";
import { Redirect } from "react-router-dom";

interface Props {}

enum Success {
  UNDETERMINED,
  SUCCESS,
  FAIL,
}

export const Login: React.SFC<Props> = (p) => {
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [success, setSuccess] = React.useState(Success.UNDETERMINED);
  const setState = (f: any) => (e: any) => f(e.target.value);

  const submitLogin = async () => {
    try {
      await login(username, password);
      setSuccess(Success.SUCCESS);
    } catch (error) {
      setSuccess(Success.FAIL);
    }
  };

  return (
    <Ui.Grid
      textAlign="center"
      style={{ height: "100vh" }}
      verticalAlign="middle"
    >
      <Ui.Grid.Column style={{ maxWidth: 450 }}>
        <Ui.Header as="h2" color="teal" textAlign="center">
          Log in to your account
        </Ui.Header>
        {success === Success.SUCCESS && (
          <div>
            <Ui.Message success>Success</Ui.Message>
            <Redirect to="/" />
          </div>
        )}
        {success === Success.FAIL && (
          <Ui.Message error>Failed to log in, try again</Ui.Message>
        )}
        <Ui.Form size="large">
          <Ui.Segment stacked>
            <Ui.Form.Input
              fluid
              icon="user"
              iconPosition="left"
              placeholder="Username"
              value={username}
              onChange={setState(setUsername)}
            />
            <Ui.Form.Input
              fluid
              icon="lock"
              iconPosition="left"
              placeholder="Password"
              type="password"
              value={password}
              onChange={setState(setPassword)}
            />

            <Ui.Button color="teal" fluid size="large" onClick={submitLogin}>
              Login
            </Ui.Button>
          </Ui.Segment>
        </Ui.Form>
      </Ui.Grid.Column>
    </Ui.Grid>
  );
};
