import * as React from "react";
import * as Tr from "transformation-matrix";

interface Props {
  elementToZoomTo?: React.MutableRefObject<any>;
}

type Transforms = Tr.Matrix;

function pythagorean(sideA: number, sideB: number) {
  return Math.sqrt(Math.pow(sideA, 2) + Math.pow(sideB, 2));
}

function decomposeMatrix(m: Tr.Matrix) {
  var E = (m.a + m.d) / 2;
  var F = (m.a - m.d) / 2;
  var G = (m.c + m.b) / 2;
  var H = (m.c - m.b) / 2;

  var Q = Math.sqrt(E * E + H * H);
  var R = Math.sqrt(F * F + G * G);
  var a1 = Math.atan2(G, F);
  var a2 = Math.atan2(H, E);
  var theta = (a2 - a1) / 2;
  var phi = (a2 + a1) / 2;

  return {
    translateX: m.e,
    translateY: m.f,
    rotate: (-phi * 180) / Math.PI,
    scaleX: Q + R,
    scaleY: Q - R,
    skew: (-theta * 180) / Math.PI,
  };
}

export const SVGPanZoom: React.SFC<Props> = (p) => {
  const [svgTransform, setSvgTransform] = React.useState<Transforms>(
    Tr.scale(1)
  );

  const [previousTouch, setPreviousTouch] = React.useState<
    React.TouchEvent<SVGSVGElement>
  >();

  const [isClicked, setIsClicked] = React.useState(false);

  const containerRef = React.useRef<HTMLDivElement>();
  const width =
    (containerRef.current &&
      containerRef.current.getBoundingClientRect().width) ||
    window.innerWidth;
  const height =
    (containerRef.current &&
      containerRef.current.getBoundingClientRect().height) ||
    window.innerHeight;

  React.useEffect(() => {
    // Reset view when window size changes
    const t = Tr.compose(
      Tr.translate(width / 2, height / 2),
      Tr.scale(2.5)
      //
    );
    setSvgTransform(t);
  }, [width, height]);

  React.useEffect(() => {
    if (!p.elementToZoomTo) {
      return;
    }
    zoomToElement(p.elementToZoomTo);
    // eslint-disable-next-line
  }, [p.elementToZoomTo]);

  if (decomposeMatrix(svgTransform).scaleX < 2) {
    const t = Tr.compose(
      Tr.translate(width / 2, height / 2),
      Tr.scale(2)
      //
    );
    setSvgTransform(t);
  }

  const zoomAroundPoint = (
    t: Transforms,
    scale: number,
    zoomCenterX: number,
    zoomCenterY: number
  ): Transforms => {
    return Tr.compose(
      Tr.translate(zoomCenterX, zoomCenterY),
      Tr.scale(scale),
      Tr.translate(-zoomCenterX, -zoomCenterY),
      t
    );
  };

  const handleOnWheel = (e: React.WheelEvent<SVGGElement>) => {
    let currentTargetRect = e.currentTarget.getBoundingClientRect();
    const event_offsetX = e.pageX - currentTargetRect.left;
    const event_offsetY = e.pageY - currentTargetRect.top;

    const decompOrig = decomposeMatrix(svgTransform);
    let scale = 1 - e.deltaY * Math.pow(decompOrig.scaleX, 1.05) * 0.0014;
    scale = Math.min(1.5, Math.max(0.5, scale));

    let t = zoomAroundPoint(svgTransform, scale, event_offsetX, event_offsetY);
    setSvgTransform(t);
  };

  const handleOnMouseMove = (e: React.MouseEvent<SVGSVGElement>) => {
    if (!isClicked) {
      return;
    }
    let t = svgTransform;
    let factor = 0.77;
    t = Tr.compose(Tr.translate(e.movementX * factor, e.movementY * factor), t);

    setSvgTransform(t);
  };

  const handleOnTouch = (e: React.TouchEvent<SVGSVGElement>) => {
    if (!previousTouch) {
      e.persist();
      setPreviousTouch(e);
      return;
    }

    let t = svgTransform;
    const touchesNew = e.touches;
    const touchesOld = previousTouch.touches;

    // single touch
    let movementX = touchesNew[0].clientX - touchesOld[0].clientX;
    let movementY = touchesNew[0].clientY - touchesOld[0].clientY;

    if (touchesNew.length > 1 && touchesOld.length > 1) {
      // multitouch
      const distNewX = touchesNew[1].clientX - touchesNew[0].clientX;
      const distNewY = touchesNew[1].clientY - touchesNew[0].clientY;
      const newCenterX = touchesNew[0].clientX + distNewX / 2;
      const newCenterY = touchesNew[0].clientY + distNewY / 2;

      const distOldX = touchesOld[1].clientX - touchesOld[0].clientX;
      const distOldY = touchesOld[1].clientY - touchesOld[0].clientY;
      const oldCenterX = touchesOld[0].clientX + distOldX / 2;
      const oldCenterY = touchesOld[0].clientY + distOldY / 2;

      const distOld = pythagorean(distOldX, distOldY);
      const distNew = pythagorean(distNewX, distNewY);

      const factor = distOld - distNew;
      let scale = 1 - factor * 0.0055;

      movementX = newCenterX - oldCenterX;
      movementY = newCenterY - oldCenterY;

      t = zoomAroundPoint(svgTransform, scale, newCenterX, newCenterY);
    }

    t = Tr.compose(Tr.translate(movementX, movementY), t);

    e.persist();
    setPreviousTouch(e);
    setSvgTransform(t);
  };

  const zoomToElement = (ref: React.MutableRefObject<SVGGElement>) => {
    let t = svgTransform;
    const ctm = ref.current.getScreenCTM();
    const bbox = ref.current.getBoundingClientRect();

    let scaleTransform = Tr.scale(1 / (bbox.height / height));
    if (width < height) {
      scaleTransform = Tr.scale(1 / (bbox.width / width));
    }

    t = Tr.transform(
      Tr.translate(width / 2, height / 2),
      scaleTransform,
      Tr.translate(-ctm.e, -ctm.f),
      t
    );
    setSvgTransform(t);
  };

  return (
    <div
      ref={containerRef}
      style={{
        width: "100%",
        height: "100vh",
        touchAction: "none",
      }}
    >
      {/* <p style={{ position: "absolute", top: 100, left: 0 }}>
        {Tr.toSVG(svgTransform)}
      </p> */}
      <svg
        width={width}
        height={height}
        onWheel={handleOnWheel}
        onMouseMove={handleOnMouseMove}
        onMouseDown={() => setIsClicked(true)}
        onMouseUp={() => setIsClicked(false)}
        onTouchMove={handleOnTouch}
        onTouchEnd={() => setPreviousTouch(null)}
      >
        <g transform={svgTransform && Tr.toSVG(Tr.compose(svgTransform))}>
          {width && p.children}
        </g>
      </svg>
    </div>
  );
};
