import * as React from "react";
import * as T from "types";
import { Link } from "react-router-dom";

interface Coords {
  x: number;
  y: number;
}

interface Props {
  // When modifying something here, check how it will affect the
  // React.memo at the bottom of the file
  path: T.Path;
  node: T.TTreeNode;
  coord: Coords;
  zoomToNode: (ref: React.MutableRefObject<SVGGElement>) => void;
}

const UnoptimizedNode: React.SFC<Props> = (p) => {
  const gRef = React.useRef<SVGGElement>();

  const urlPath = T.useGetCurrentPath();
  const [didInitialZoom, setDidInitialZoom] = React.useState(false);

  React.useEffect(() => {
    if (getLevel() > 0.1) {
      setDidInitialZoom(true);
      return;
    }

    if (urlPath.equals(p.path) && !didInitialZoom && getLevel() < 0.1) {
      p.zoomToNode(gRef);
    }
    // eslint-disable-next-line
  }, [gRef, p.zoomToNode, p.path, didInitialZoom, urlPath]);

  const getLevel = () => {
    const gRect = gRef.current.getBoundingClientRect();
    const level = gRect.width / window.innerWidth;
    return level;
  };

  const handleClickText = (e: React.MouseEvent) => {
    const level = getLevel();
    const canClick = level > 0.05 || (level > 0.1 && p.node.children.isEmpty());
    if (!canClick) {
      p.zoomToNode(gRef);
      e.stopPropagation();
      e.preventDefault();
    }
  };

  const handleClickBackground = (e: React.MouseEvent) => {
    const level = getLevel();
    const canClick = level > 0.2;
    if (!canClick) {
      p.zoomToNode(gRef);
      e.stopPropagation();
      e.preventDefault();
    }
  };

  return (
    <g
      ref={gRef}
      transform={`
        translate(${p.coord.x}, ${p.coord.y})
        scale(${1 / p.path.size})`}
    >
      <circle
        style={{ zIndex: 100 * p.path.size }}
        onClick={handleClickBackground}
        r={140 / p.path.size}
        fillOpacity={0.0}
      />

      <Link onClick={handleClickText} to={T.path2URL(p.path)}>
        <circle r={4} fill="#36a17f" />
        <text dx={8} alignmentBaseline="middle">
          {p.node.title}
        </text>
      </Link>
      <g>
        {p.node.children.map((c, i) => {
          const depth = p.path.size;
          const siblingsCount = p.node.children.size;
          const startAngle = Math.PI;
          let angle = startAngle + 2 * Math.PI * (i / siblingsCount);
          if (angle > 2 * Math.PI - 0.2) {
            angle = angle + 0.4;
          }
          const coord = {
            x: Math.cos(angle) * 100 * (1 / depth),
            y: Math.sin(angle) * 100 * (1 / depth),
          };
          const childPath = p.path.push(c.title);
          return (
            <g key={childPath.join("/")}>
              {/* parent-child lines */}
              {/* <line
                x1={0}
                y1={0}
                x2={coord.x}
                y2={coord.y}
                stroke="black"
                strokeWidth={0.1}
                strokeOpacity={0.3}
              /> */}
              <Node
                path={childPath}
                node={c}
                coord={coord}
                zoomToNode={p.zoomToNode}
              />
            </g>
          );
        })}
      </g>
    </g>
  );
};

export const Node = UnoptimizedNode;

// export const Node = React.memo(UnoptimizedNode, (prev, next) => {
//   return [
//     prev.node.equals(next.node),
//     prev.path.equals(next.path),
//     prev.coord.x === next.coord.x,
//     prev.coord.y === next.coord.y,
//     // prev.zoomToNode === next.zoomToNode, // only need it once
//   ].every((s) => s);
// });
