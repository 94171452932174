import * as React from "react";
import { RenderMarkProps, RenderBlockProps, Editor, Plugin } from "slate-react";
import { MarkType, BlockType } from "../schema";

import { onKeyDown } from "./hotkeys";
import { css } from "emotion";
import { Node } from "slate";

const renderMark = (p: RenderMarkProps) => {
  switch (p.mark.type) {
    case MarkType.Bold:
      return <strong {...p.attributes} children={p.children} />;
    case MarkType.Italic:
      return <em {...p.attributes} children={p.children} />;
    case MarkType.Underlined:
      return <u {...p.attributes} children={p.children} />;
    default:
      return undefined;
  }
};

const renderBlock = (p: RenderBlockProps, editor: Editor, next: any) => {
  switch (p.node.type) {
    case BlockType.Paragraph: {
      return (
        <p
          className={css`
            font-size: 1em;
            font-weight: 500;
            font-family: "Quicksand", sans-serif;
          `}
          {...p.attributes}
          children={p.children}
        />
      );
    }
    default: {
      return next();
    }
  }
};

export const onBlur = (event: any, editor: Editor, next: any) => {
  const isEmpty = (b: Node) =>
    b.object === "block" && b.type === BlockType.Paragraph && b.text === "";

  while (
    isEmpty(editor.value.document.nodes.first()) &&
    !(editor.value.document.nodes.size < 3)
  ) {
    editor.removeNodeByKey(editor.value.document.nodes.first().key);
  }

  while (
    isEmpty(editor.value.document.nodes.last()) &&
    !(editor.value.document.nodes.size < 3)
  ) {
    editor.removeNodeByKey(editor.value.document.nodes.last().key);
  }
};

export const ParagraphPlugin = {
  onKeyDown,
  renderMark,
  renderBlock,
  onBlur,
} as Plugin;
