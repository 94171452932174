import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { Global, css } from "@emotion/core";
import "semantic-ui-css/semantic.min.css";
import { Login } from "containers/Login";
import * as LocComps from "./containers/Components";

import { useDocStore } from "containers/docStore";
import NavBar from "containers/NavBar";
import Collection from "containers/Collection";
import { useUserStore } from "containers/userStore";
import { Tree } from "containers/Tree";

const globalStyles = css`
  @import url("https://fonts.googleapis.com/css?family=Quicksand:400,500&display=swap");
`;

const App: React.FC = () => {
  const docStore = useDocStore();
  const userStore = useUserStore();

  // Load user data
  React.useEffect(() => {
    userStore.actions.loadUser();
    // eslint-disable-next-line
  }, []);

  // Load metadata
  React.useEffect(() => {
    docStore.actions.loadOwnTree();
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <Global styles={globalStyles} />
      <userStore.Context>
        <docStore.Context>
          <Router>
            <NavBar user={userStore.state} tree={docStore.state.tree} />
            <Switch>
              <Route path="/t/:user">
                <Tree root={docStore.state.tree} />
              </Route>
              <Route component={Login} path="/login" />
              <Route path="/d/:path">
                <LocComps.AppOuter>
                  <LocComps.AppInner>
                    <Collection docState={docStore.state} level={0} />
                  </LocComps.AppInner>
                </LocComps.AppOuter>
              </Route>
              <Route path="/">
                {userStore.state.username ? (
                  <Redirect to={`/d/${userStore.state.username}`} />
                ) : (
                  <a href="/login">Login</a>
                )}
              </Route>
            </Switch>
          </Router>
        </docStore.Context>
      </userStore.Context>
    </div>
  );
};

export default App;
