import * as Im from "immutable";
import { Value } from "slate";
import { useLocation } from "react-router";

export type Path = Im.List<string>;

export const makePath = (s: string) =>
  Im.List(s.split("/")).filter((a) => a) as Path;

export const useGetCurrentPath = () => {
  const location = useLocation();
  if (
    !location ||
    !(
      location.pathname.startsWith("/d/") || location.pathname.startsWith("/t/")
    )
  ) {
    return null;
  }
  return makePath(location.pathname.slice(3));
};

export const path2URL = (p: Path) => `/d/${p.join("/")}/`;

export const title2pathSafeTitle = (n: string) => n.replace(" ", "_");
export const pathSafetitle2title = (n: string) => n.replace("_", " ");

export const tree2PathList = (tree: TTreeNode) => {
  const inner = (parentPath: Path, tree: TTreeNode) => {
    if (!tree) {
      return;
    }
    const path = parentPath.push(tree.title);
    res = res.push(path);

    tree.children.forEach((c) => inner(path, c));
  };

  let res = Im.List<Path>();
  inner(Im.List(), tree);

  return res;
};

export enum Mode {
  Tree = "t",
  Data = "d",
}

export const useGetCurrentMode = (): Mode => {
  const location = useLocation();

  if (!location) {
    return null;
  }

  const modeString = location.pathname.split("/");
  if (modeString.length < 2) {
    return null;
  }

  return modeString[1] as Mode;
};

interface IDocument {
  title: string;
  body: Value;
  date_created: Date;
  date_modified: Date;
}

export const initValue = (tags: string[]) =>
  Value.fromJSON({
    object: "value",
    document: {
      object: "document",
      data: {},
      nodes: [
        {
          object: "block",
          type: "paragraph",
          data: {},
          nodes: [
            {
              object: "text",
              text: "",
              marks: [],
            },
          ],
        },
        {
          object: "block",
          type: "paragraph",
          data: {},
          nodes: [].concat.apply(
            [],
            tags.map((tag) => [
              {
                object: "inline",
                type: "simpletag",
                data: { text: tag },
                nodes: [],
              },
              {
                object: "text",
                text: " ",
                marks: [],
              },
            ])
          ),
          // nodes2: [
          //   {
          //     object: "text",
          //     text: "",
          //     marks: []
          //   },
          //   {
          //     object: "inline",
          //     type: "simpletag",
          //     data: {},
          //     nodes: [
          //       {
          //         object: "text",
          //         text: "a",
          //         marks: []
          //       }
          //     ]
          //   },
          //   {
          //     object: "text",
          //     text: " ",
          //     marks: []
          //   },
          //   {
          //     object: "inline",
          //     type: "simpletag",
          //     data: {},
          //     nodes: [
          //       {
          //         object: "text",
          //         text: "b",
          //         marks: []
          //       }
          //     ]
          //   },
          //   {
          //     object: "text",
          //     text: " ",
          //     marks: []
          //   }
          // ]
        },
      ],
    },
  } as any);

export const Document = Im.Record<IDocument>({
  title: "",
  body: initValue([]),
  date_created: new Date(),
  date_modified: new Date(),
});

export type TDocument = Im.Record<IDocument> & Readonly<IDocument>;

// export type TDocumentCollection = Im.List<TDocument>;
export type TDocumentMap = Im.Map<Path, TDocument>;

interface ITreeNode {
  title: string;
  children: Im.List<TTreeNode>;
}

export const TreeNode = Im.Record<ITreeNode>({
  title: "",
  children: Im.List(),
});

export type TTreeNode = Im.Record<ITreeNode> & Readonly<ITreeNode>;

export const getNodeAtPath = (root: TTreeNode, path: Path): TTreeNode => {
  if (!root || !path) {
    return null;
  }

  path = path.slice(1);
  if (path.isEmpty()) {
    return root;
  }

  const child = root.children.find((c) => c.title === path.get(0));
  return getNodeAtPath(child, path);
};

export const getChildrenAtPath = (
  root: TTreeNode,
  path: Path
): Im.List<Path> => {
  const node = getNodeAtPath(root, path);
  return !node ? Im.List() : node.children.map((c) => path.push(c.title));
};

export const treeDFS = (root: TTreeNode, basePath: Path) => {
  if (!root) {
    return Im.List<Path>();
  }

  let res = Im.List<Path>([]);
  let baseNode = getNodeAtPath(root, basePath);

  const recur = (node: TTreeNode, path: Path) => {
    if (!node) {
      return null;
    }
    path = path.push(node.title);
    res = res.push(path);
    node.children.forEach((c) => recur(c, path));
  };

  recur(baseNode, basePath.slice(0, -1));

  return res;
};

// Profile
interface IProfile {}

export const Profile = Im.Record<IProfile>({});

export type TProfile = Im.Record<IProfile> & Readonly<IProfile>;

// User
interface IUser {
  username: string;
  email: string;
  profile: TProfile;
}

export const User = Im.Record<IUser>({
  username: "",
  email: "",
  profile: Profile(),
});

export type TUser = Im.Record<IUser> & Readonly<IUser>;
