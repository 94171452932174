import * as React from "react";
import * as T from "types";
import { Editor as SlateEditor, OnChangeParam } from "slate-react";
import { ParagraphPlugin } from "./Paragraph";
import { schema } from "./schema";
import { ListPlugin } from "./List";
import { LinkPlugin } from "./Link";
import { ImagePlugin } from "./Image";
import { VideoPlugin } from "./Video";
import { CodePlugin } from "./Code";
import { InternalLinkPlugin } from "./InternalLink";

const plugins = [
  LinkPlugin,
  InternalLinkPlugin,
  ParagraphPlugin,
  CodePlugin,
  ListPlugin,
  VideoPlugin,
  ImagePlugin,
];

interface Props {
  doc: T.TDocument;
  tree: T.TTreeNode;
  onDocUpdate: (d: T.TDocument) => void;
  editorRef: React.MutableRefObject<any>;
  readOnly?: boolean;
}

const Sl = SlateEditor as any;

const Editor: React.SFC<Props> = (p) => {
  const onChange = (change: OnChangeParam) => {
    p.onDocUpdate(p.doc.set("body", change.value));
  };

  return (
    <div>
      <Sl
        spellCheck={false}
        plugins={plugins}
        schema={schema}
        value={p.doc.body}
        onChange={onChange}
        ref={p.editorRef}
        tree={p.tree}
        readOnly={p.readOnly}
      />
    </div>
  );
};

export default Editor;
