import * as React from "react";
import * as Ui from "semantic-ui-react";
import * as T from "types";
import { NewPage } from "./PageCreationPopup";
import { LinkCrumb } from "./LinkCrumb";
import { useHistory } from "react-router-dom";
import { NodeDropdown } from "containers/NodeDropdown";
import styled from "@emotion/styled";

interface Props {
  user: T.TUser;
  tree: T.TTreeNode;
}

const ContainerAncestors = styled.span`
  @media only screen and (max-width: 415px) {
    display: none;
  }
`;
const ContainerDescendants = styled.span`
  @media only screen and (max-width: 812px) {
    display: none;
  }
`;

const NavBar: React.SFC<Props> = (p) => {
  const path = T.useGetCurrentPath();
  const mode = T.useGetCurrentMode();
  const history = useHistory();
  const node = T.getNodeAtPath(p.tree, path);

  const handleHomeButton = () => {
    switch (mode) {
      case T.Mode.Data: {
        history.push(`/t/${path.slice(0, 1).join("/")}`);
        return;
      }

      case T.Mode.Tree: {
        history.push("/ll");
        return;
      }
      default: {
        return;
      }
    }
  };

  const navigate2Path = (p: T.Path) => history.push(T.path2URL(p));

  return (
    <Ui.Menu fixed="top" style={{ backgroundColor: "#36a17f" }}>
      <Ui.Menu.Item onClick={handleHomeButton}>
        <Ui.Icon name="home" inverted />
      </Ui.Menu.Item>
      <ContainerAncestors>
        <Ui.Menu.Item>
          <Ui.Breadcrumb>
            {path &&
              path
                .map((_, i) => (
                  <LinkCrumb
                    key={i}
                    isLast={i === path.size - 1}
                    path={path.slice(0, i + 1)}
                  />
                ))
                .interpose(<Ui.Breadcrumb.Divider />)}
          </Ui.Breadcrumb>
        </Ui.Menu.Item>
      </ContainerAncestors>
      <Ui.Menu.Item>
        <ContainerDescendants>
          <Ui.Breadcrumb>
            {node &&
              node.children
                .map((c) => (
                  <LinkCrumb
                    isLast={false}
                    key={c.title}
                    path={path.push(c.title)}
                  />
                ))
                .interpose(
                  <Ui.Breadcrumb.Divider>&middot;</Ui.Breadcrumb.Divider>
                )}
            {node && !node.children.isEmpty() && (
              <Ui.Breadcrumb.Divider>&middot;</Ui.Breadcrumb.Divider>
            )}
          </Ui.Breadcrumb>
        </ContainerDescendants>
        <Ui.Breadcrumb.Section>
          <NewPage parentPath={path} />
        </Ui.Breadcrumb.Section>
      </Ui.Menu.Item>
      <Ui.Menu.Item>
        <NodeDropdown tree={p.tree} onSelect={navigate2Path} />
      </Ui.Menu.Item>
    </Ui.Menu>
  );
};

export default NavBar;
