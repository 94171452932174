import * as React from "react";
import * as Ui from "semantic-ui-react";
import * as T from "types";
import * as Api from "api";
import Editor from "./Editor";
import { useAfterQuiet } from "containers/customHooks";
import { DocStoreDispatchCtx } from "containers/docStore";
import { css } from "emotion";
import styled from "@emotion/styled";
import { Menu } from "./Menu";
import { DocTitle } from "./DocTitle";

interface Props {
  tree: T.TTreeNode;

  doc: T.TDocument;
  path: T.Path;
  isMain: boolean;

  previewMode?: boolean;
  togglePreviewMode: () => void;

  areChildrenOpen: boolean;
  toggleAreChildrenOpen: () => void;

  areDescendantsOpen: boolean;
  toggleAreDescendantsOpen: () => void;
}

const Container = styled.div<{ preview: boolean }>`
  position: relative;

  ${(p) =>
    p.preview &&
    `
    max-height: 20em;
    overflow-y: hidden;

    &:after {
      position: absolute;
      bottom: 0;  
      height: 100%;
      width: 100%;
      content: "";
      background: linear-gradient(to top,
        rgba(255,255,255, 1) 20%, 
        rgba(255,255,255, 0) 80%
      );
      pointer-events: none; /* so the text is still selectable */
    }
`}
`;

const Document: React.SFC<Props> = (p) => {
  const actions = React.useContext(DocStoreDispatchCtx);
  const [isSavingError, setIsSavingError] = React.useState(false);
  const editorRef = React.useRef();
  const [isEditMenuOpen, setIsEditMenuOpen] = React.useState(false);

  // Load doc
  React.useEffect(() => {
    if (p.doc && p.doc.body) {
      return;
    }
    actions.loadDoc(p.path);
    // eslint-disable-next-line
  }, [p.doc && p.doc.body != null]);

  const onDocUpdate = (d: T.TDocument) => {
    actions.updateDoc(p.path, d);
  };

  // save doc

  const isDirty = useAfterQuiet(
    (setNotDirty) => {
      Api.patchDoc(p.path, p.doc)
        .then(() => {
          setNotDirty();
          setIsSavingError(false);
        })
        .catch(() => {
          setIsSavingError(true);
          alert(
            "Couldn't save document. Please check your internet connection"
          );
        });
    },
    [p.doc && p.doc.body && p.doc.body.document, isSavingError],
    1000,
    [p.doc && p.doc.body && p.doc.body.document]
  );

  return (
    <Container preview={p.previewMode}>
      <DocTitle path={p.path} isMain={p.isMain} />

      <span
        className={css`
          position: absolute;
          right: 0;
          top: 0px;
        `}
      >
        {isDirty && <Ui.Icon name="save outline" />}
        <Ui.Icon
          onClick={p.toggleAreChildrenOpen}
          name={p.areChildrenOpen ? "chevron up" : "chevron down"}
        />
        <Ui.Icon
          name={p.areDescendantsOpen ? "angle double up" : "angle double down"}
          onClick={p.toggleAreDescendantsOpen}
        />
        <Ui.Icon
          onClick={() => setIsEditMenuOpen(!isEditMenuOpen)}
          name="bars"
        />
      </span>

      {isEditMenuOpen && (
        <Menu doc={p.doc} path={p.path} editorRef={editorRef} />
      )}

      {isSavingError && (
        <Ui.Message error>
          Could not save note
          <Ui.Button primary onClick={() => setIsSavingError(false)}>
            Retry
          </Ui.Button>
        </Ui.Message>
      )}

      <Ui.Divider />

      <div onClick={() => p.previewMode && p.togglePreviewMode()}>
        {p.doc && p.doc.body && (
          <Editor
            readOnly={p.previewMode}
            tree={p.tree}
            doc={p.doc}
            onDocUpdate={onDocUpdate}
            editorRef={editorRef}
          />
        )}
      </div>
    </Container>
  );
};

export default React.memo(Document);
