import * as React from "react";
import isUrl from "is-url";
import {
  RenderInlineProps,
  Editor,
  Plugin,
  getEventTransfer,
} from "slate-react";
import { InlineType } from "../schema";
import { css } from "emotion";
import { Icon } from "semantic-ui-react";

const renderInline = (p: RenderInlineProps, editor: Editor, next: any) => {
  switch (p.node.type) {
    case InlineType.Link: {
      const url = p.node.data.get("url");
      return (
        <div
          {...p.attributes}
          className={css`
            position: relative;
            display: inline;
          `}
        >
          <a href={url}>{p.children}</a>
          {p.isSelected && (
            <div
              contentEditable={false}
              className={css`
                position: absolute;
                left: 0px;
                top: 1.3rem;
                background-color: white;
                z-index: 99;
                width: 25em;
                padding: 0.7em 0.2em;
                border: 1px solid darkgray;
                border-radius: 4px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                font-size: 0.95em;
                box-shadow: 1px 1px gray;
              `}
            >
              <a className={css``} target="_blank" href={url}>
                <Icon name="share square" />
                {url}
              </a>
            </div>
          )}
        </div>
      );
    }

    default: {
      return next();
    }
  }
};

function wrapLink(editor, url) {
  editor.wrapInline({
    type: InlineType.Link,
    data: { url },
  });

  editor.moveToEnd();
}

function unwrapLink(editor) {
  editor.unwrapInline(InlineType.Link);
}

function hasLinks(editor: Editor) {
  return editor.value.inlines.some((i) => i.type === InlineType.Link);
}

const onPaste = (event: React.ClipboardEvent, editor: Editor, next: any) => {
  const transfer = getEventTransfer(event) as any;
  const { type, text } = transfer;
  if (type !== "text" && type !== "html") return next();
  if (!isUrl(text)) return next();

  if (editor.value.selection.isCollapsed) {
    editor.insertInline({
      type: InlineType.Link,
      data: { url: text },
      nodes: [
        {
          object: "text",
          text,
        },
      ],
    });
  } else {
    if (hasLinks(editor)) {
      editor.command(unwrapLink);
    }

    event.preventDefault();
    editor.command(wrapLink, text);
  }
};

export const LinkPlugin = {
  renderInline,
  onPaste,
} as Plugin;
