import * as React from "react";
import * as Ui from "semantic-ui-react";
import * as T from "types";
import { LinkCrumb } from "containers/NavBar/LinkCrumb";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";

interface Props {
  path: T.Path;
  isMain: boolean;
}

const ContainerAncestors = styled.span<{ isMain: boolean }>`
  ${(p) =>
    p.isMain &&
    `
      @media only screen and (min-width: 415px) {
        display: none;
      }
  `}
`;

export const DocTitle: React.SFC<Props> = (p) => {
  const ancestors = p.path.slice(0, -1);

  return (
    <div>
      <Ui.Breadcrumb>
        <ContainerAncestors isMain={p.isMain}>
          {ancestors
            .map((_, i) => (
              <LinkCrumb
                key={i}
                isLast={false}
                path={ancestors.slice(0, i + 1)}
              />
            ))
            .interpose(<Ui.Breadcrumb.Divider />)}
          {!ancestors.isEmpty() && <Ui.Breadcrumb.Divider />}
        </ContainerAncestors>
        {/* <DocTitle path={p.path} isMain /> */}
        <Ui.Header style={{ display: "inline" }}>
          <Link
            style={{ color: "inherit", textDecoration: "none" }}
            to={T.path2URL(p.path)}
          >
            {T.pathSafetitle2title(p.path.last())}
          </Link>
        </Ui.Header>
      </Ui.Breadcrumb>
    </div>
  );
};
