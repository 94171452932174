import * as React from "react";
import * as LocalComponents from "./Components";
import * as T from "types";
import { DocStoreState } from "containers/docStore";
import Document from "containers/Document";
import * as Ui from "semantic-ui-react";

interface Props {
  docState: DocStoreState;
  path?: T.Path;
  areDescendantsOpen?: boolean;
  isSubCollection?: boolean;
  level: number;
}

function useBinaryToggle(initialValue?: boolean): [boolean, () => void] {
  const [state, setState] = React.useState(initialValue);
  const toggler = () => setState(!state);
  return [state, toggler];
}

const Collection: React.SFC<Props> = (p) => {
  const path = p.path || T.useGetCurrentPath();

  const [areChildrenOpen, toggleChildrenOpen] = useBinaryToggle();
  const [areDescendantsOpen, toggleDescendantsOpen] = useBinaryToggle();
  const [previewMode, togglePreviewMode] = useBinaryToggle(p.isSubCollection);

  const childPaths = T.getChildrenAtPath(p.docState.tree, path);

  const showControls = !childPaths.isEmpty() && !p.areDescendantsOpen;
  const showChildren =
    !p.isSubCollection ||
    areChildrenOpen ||
    areDescendantsOpen ||
    p.areDescendantsOpen;

  if (p.docState.tree && path.isEmpty()) {
    return <p>Page not found</p>;
  }

  return (
    <div>
      <LocalComponents.NoteContainer level={p.level}>
        <Document
          isMain={!p.isSubCollection}
          tree={p.docState.tree}
          path={path}
          doc={p.docState.docs.get(path)}
          previewMode={previewMode}
          togglePreviewMode={togglePreviewMode}
          areChildrenOpen={showChildren}
          toggleAreChildrenOpen={toggleChildrenOpen}
          areDescendantsOpen={areDescendantsOpen}
          toggleAreDescendantsOpen={toggleDescendantsOpen}
        />
      </LocalComponents.NoteContainer>

      {showChildren &&
        childPaths.map((childPath) => (
          <Collection
            isSubCollection
            docState={p.docState}
            path={childPath}
            areDescendantsOpen={areDescendantsOpen || p.areDescendantsOpen}
            level={p.level + 1}
          />
        ))}
    </div>
  );
};

export default Collection;
