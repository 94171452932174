import * as React from "react";
import * as Ui from "semantic-ui-react";
import * as T from "types";
import { Link } from "react-router-dom";

export const LinkCrumb = (p: { path: T.Path; isLast: boolean }) => (
  <Ui.Breadcrumb.Section>
    <Link
      style={{ color: "black", fontWeight: p.isLast && 700 }}
      to={T.path2URL(p.path)}
    >
      {T.pathSafetitle2title(p.path.last())}
    </Link>
  </Ui.Breadcrumb.Section>
);
