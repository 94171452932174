import styled from "@emotion/styled";

export const NoteContainer = styled.div<{ level: number }>`
  // width: ${(p) => 100 - p.level * 10}%;
  // margin-right: ${(p) => p.level * 1}em;
  font-size: 1.15em;
  background-color: #fff;
  padding: 0.7em;
  border-radius: 3px;
  margin-bottom: 1em;
  background-color: #f6f6f6;
  background-color: #fff;
`;
