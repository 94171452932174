import * as React from "react";
import {
  RenderInlineProps,
  Editor,
  Plugin,
  RenderBlockProps,
} from "slate-react";
import { InlineType } from "../schema";
import { Inline } from "slate";
import { List } from "immutable";
import { NodeDropdown } from "containers/NodeDropdown";
import { Link } from "react-router-dom";
import { path2URL, Path } from "types";

const renderInline = (p: RenderInlineProps, editor: Editor, next: any) => {
  switch (p.node.type) {
    case InlineType.InternalLink: {
      const path = List(p.node.data.get("path")) as Path;

      return <Link to={path2URL(path)}>{path.last()}</Link>;
    }

    default: {
      return next();
    }
  }
};

const renderBlock = (p: RenderBlockProps, editor: Editor, next: any) => {
  const contained = editor.value.selection.start.isInNode(p.node);

  if (contained) {
    const offset = editor.value.selection.start.offset;
    const textBefore = p.node.getText().slice(0, offset);
    const parts = textBefore.split(" ");
    const lastWord = parts[parts.length - 1];
    if (lastWord.startsWith("@")) {
      const onChoice = (path: Path) => {
        const i = Inline.create({
          type: InlineType.InternalLink,
          data: { path: path.toJS() },
        });

        editor.deleteBackward(lastWord.length);
        editor.insertInlineAtRange(editor.value.selection, i);
        editor.insertText(" ");
      };
      return (
        <span>
          {next()}
          <div contentEditable={false}>
            <NodeDropdown
              searchQuery={lastWord.slice(1)}
              tree={(editor.props as any).tree}
              onSelect={onChoice}
            />
          </div>
        </span>
      );
    }
  }

  return next();
};

export const InternalLinkPlugin = {
  renderInline,
  renderBlock,
} as Plugin;
